<template>
	<ValidationObserver ref="form_pagamento" v-slot="{ invalid }">
		<form onsubmit="return false">
			<Card
				v-if="assistido"
				bars
				background="blur"
				class="view-card-info"
				size="normal"
			>
				<div>
					<p
						v-if="infos.social_name !== ''"
					>
						<b>Nome Social:</b> {{ infos.social_name }}
					</p>
					<p><b>Nome Completo:</b> {{ infos.name }} </p>
					<p><b>CPF:</b> {{ infos.cpf }} </p>
				</div>
			</Card>
			<fieldset v-if="!gratuito">
				<SelectMetodoPagamento 
					v-model="form.metodo_pagamento"
					name="metodo_pagamento"
					label="Métodos de pagamento"
					:metodos="metodos"
				/>
			</fieldset>
			<component v-if="!gratuito" :is="metodoPagamentoComponent" ref="form_dados_cartao" />
			<div v-if="metodoSelecionado" class="header-parcelamento">
				<h2>Parcelamento</h2>
				<div class="colunas">
					<div>
						<p>Selecione em quantas vezes gostaria de parcelar seu curso:</p>
						<ValidationProvider
							v-slot="{ errors }"
							rules="required"
							name="Parcelas"
						>
							<b-field
								:message="errors[0] ? errors[0] : ''"
								class="select-parcelas"
								:type="errors.length > 0 ? 'is-danger' : ''"
							>
								<SelectComponent 
									v-model="form.parcelas" 
									dataId="input_parcela" 
									dataListId="list_parcela" 
									dataBtnId="btn_parcela" 
									name="Parcelas" 
									optionLabel="label" 
									optionValue="id" 
									placeholder="Parcelas" 
									:disabled="!exibirParcelamento" 
									:options="parcelas" 
								/>
							</b-field>
						</ValidationProvider>
					</div>
					<div>
						<ValidationProvider
							v-slot="{ errors }"
							name="Cupom"
						>
							<b-field>
								<label class="label">
									Código do cupom (opcional)
									<span v-show="loadingCupom" class="loader_b ml-2" />
								</label>
								<b-input
									v-model="form.cupom"
									v-on:blur="cupomHandle"
									:disabled="loadingCupom"
								/>
								<p
									v-if="(errors.length > 0 || successCupom || errorCupom)"
									class="help no-icon"
									:class="{
										'is-danger': (errors.length > 0 || errorCupom),
										'is-info': successCupom
									}"
								>
									{{ errors[0] || errorCupom || successCupom }}
								</p>
							</b-field>
						</ValidationProvider>
						<a v-if="showModalVencimento" class="link-cupom"
							@click="openModal">
							<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M0 2.25C0 1.65326 0.237053 1.08097 0.65901 0.65901C1.08097 0.237053 1.65326 0 2.25 0H11.75C12.3467 0 12.919 0.237053 13.341 0.65901C13.7629 1.08097 14 1.65326 14 2.25V11H18V14.75C18 15.612 17.6576 16.4386 17.0481 17.0481C16.4386 17.6576 15.612 18 14.75 18H3.25C2.38805 18 1.5614 17.6576 0.951903 17.0481C0.34241 16.4386 0 15.612 0 14.75V2.25ZM14 16.5H14.75C15.2141 16.5 15.6592 16.3156 15.9874 15.9874C16.3156 15.6592 16.5 15.2141 16.5 14.75V12.5H14V16.5ZM2.25 1.5C2.05109 1.5 1.86032 1.57902 1.71967 1.71967C1.57902 1.86032 1.5 2.05109 1.5 2.25V14.75C1.5 15.716 2.284 16.5 3.25 16.5H12.5V2.25C12.5 2.05109 12.421 1.86032 12.2803 1.71967C12.1397 1.57902 11.9489 1.5 11.75 1.5H2.25ZM4.25 4C4.05109 4 3.86032 4.07902 3.71967 4.21967C3.57902 4.36032 3.5 4.55109 3.5 4.75C3.5 4.94891 3.57902 5.13968 3.71967 5.28033C3.86032 5.42098 4.05109 5.5 4.25 5.5H9.75C9.94891 5.5 10.1397 5.42098 10.2803 5.28033C10.421 5.13968 10.5 4.94891 10.5 4.75C10.5 4.55109 10.421 4.36032 10.2803 4.21967C10.1397 4.07902 9.94891 4 9.75 4H4.25ZM3.5 8.75C3.5 8.55109 3.57902 8.36032 3.71967 8.21967C3.86032 8.07902 4.05109 8 4.25 8H9.75C9.94891 8 10.1397 8.07902 10.2803 8.21967C10.421 8.36032 10.5 8.55109 10.5 8.75C10.5 8.94891 10.421 9.13968 10.2803 9.28033C10.1397 9.42098 9.94891 9.5 9.75 9.5H4.25C4.05109 9.5 3.86032 9.42098 3.71967 9.28033C3.57902 9.13968 3.5 8.94891 3.5 8.75ZM4.25 12C4.05109 12 3.86032 12.079 3.71967 12.2197C3.57902 12.3603 3.5 12.5511 3.5 12.75C3.5 12.9489 3.57902 13.1397 3.71967 13.2803C3.86032 13.421 4.05109 13.5 4.25 13.5H7.25C7.44891 13.5 7.63968 13.421 7.78033 13.2803C7.92098 13.1397 8 12.9489 8 12.75C8 12.5511 7.92098 12.3603 7.78033 12.2197C7.63968 12.079 7.44891 12 7.25 12H4.25Z"
									fill="#33C1D5" />
							</svg>
							Visualize suas próximas parcelas
						</a>
					</div>
				</div>
				<div
					v-if="showVencimento"
					class="mt-6"
				>
					<ValidationProvider
						name="Data_Vencimento"
						rules="required"
					>
						<SelectVencimento
							dataId="radio_dia_pagamento"
							v-model="form.data_vencimento"
							label="Selecione a data de vencimento das próximas parcelas:"
							name="data_vencimento"
							:options="diasDeVencimento"
						/>
					</ValidationProvider>
				</div>
			</div>
			<div 
				v-if="gratuito || metodoSelecionado"
				class="resume-margin"
			>
				<ComprarInfo 
					:resumo="resumo"
					:curso="curso"
					:gratuito="gratuito"
				/>
			</div>
			<div
				v-if="gratuito || metodoSelecionado"
				class="resume-margin"
			>
				<ValidationProvider
					name="Contrato"
					rules="required"
				>
					<CheckBox
						v-model="form.contrato_matricula"
						open-to-allow
						class="mb-4"
						name="contrato_matricula"
						label="Contrato da matrícula (obrigatório)"
						:texto="contrato"
						:disabled="contrato.length === 0"
						:loading="loadingContrato"
					/>
				</ValidationProvider>
				<ValidationProvider
					name="Declaracao"
					rules="required"
					v-show="!cursoLivre"
				>
					<CheckBox
						v-model="form.declaracao_graduacao"
						name="declaracao_graduacao"
						label="Declaro que já sou graduado no ensino superior nesta data (obrigatório)"
					/>
				</ValidationProvider>
			</div>
			<p class="text-nao-graduacao" v-show="!cursoLivre">
				Não é graduado? <a href="https://www.ebradi.com.br/start" target="_blank">Clique aqui</a>
			</p>
			<div class="btns">
				<b-button
					v-if="!assistido"
					type="is-secondary"
					class="btn-margin-right"
					rounded
					outlined
					@click="handleBack"
					data-testeid="btn-voltar"
				>
					Voltar
				</b-button>
				<b-button
					type="is-secondary"
					rounded
					:disabled="isFormInvalid(invalid)"
					@click="submitForm"
					data-testeid="btn-concluir"
				>
					Concluir
				</b-button>
			</div>
		</form>
		<Modal v-model="showModal">
			<div class="header-modal-parcela">
				<div
					class="time"
					@click="closeModal"
				>
					X
				</div>
				<svg
					width="18"
					height="18"
					viewBox="0 0 18 18"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M0 2.25C0 1.65326 0.237053 1.08097 0.65901 0.65901C1.08097 0.237053 1.65326 0 2.25 0H11.75C12.3467 0 12.919 0.237053 13.341 0.65901C13.7629 1.08097 14 1.65326 14 2.25V11H18V14.75C18 15.612 17.6576 16.4386 17.0481 17.0481C16.4386 17.6576 15.612 18 14.75 18H3.25C2.38805 18 1.5614 17.6576 0.951903 17.0481C0.34241 16.4386 0 15.612 0 14.75V2.25ZM14 16.5H14.75C15.2141 16.5 15.6592 16.3156 15.9874 15.9874C16.3156 15.6592 16.5 15.2141 16.5 14.75V12.5H14V16.5ZM2.25 1.5C2.05109 1.5 1.86032 1.57902 1.71967 1.71967C1.57902 1.86032 1.5 2.05109 1.5 2.25V14.75C1.5 15.716 2.284 16.5 3.25 16.5H12.5V2.25C12.5 2.05109 12.421 1.86032 12.2803 1.71967C12.1397 1.57902 11.9489 1.5 11.75 1.5H2.25ZM4.25 4C4.05109 4 3.86032 4.07902 3.71967 4.21967C3.57902 4.36032 3.5 4.55109 3.5 4.75C3.5 4.94891 3.57902 5.13968 3.71967 5.28033C3.86032 5.42098 4.05109 5.5 4.25 5.5H9.75C9.94891 5.5 10.1397 5.42098 10.2803 5.28033C10.421 5.13968 10.5 4.94891 10.5 4.75C10.5 4.55109 10.421 4.36032 10.2803 4.21967C10.1397 4.07902 9.94891 4 9.75 4H4.25ZM3.5 8.75C3.5 8.55109 3.57902 8.36032 3.71967 8.21967C3.86032 8.07902 4.05109 8 4.25 8H9.75C9.94891 8 10.1397 8.07902 10.2803 8.21967C10.421 8.36032 10.5 8.55109 10.5 8.75C10.5 8.94891 10.421 9.13968 10.2803 9.28033C10.1397 9.42098 9.94891 9.5 9.75 9.5H4.25C4.05109 9.5 3.86032 9.42098 3.71967 9.28033C3.57902 9.13968 3.5 8.94891 3.5 8.75ZM4.25 12C4.05109 12 3.86032 12.079 3.71967 12.2197C3.57902 12.3603 3.5 12.5511 3.5 12.75C3.5 12.9489 3.57902 13.1397 3.71967 13.2803C3.86032 13.421 4.05109 13.5 4.25 13.5H7.25C7.44891 13.5 7.63968 13.421 7.78033 13.2803C7.92098 13.1397 8 12.9489 8 12.75C8 12.5511 7.92098 12.3603 7.78033 12.2197C7.63968 12.079 7.44891 12 7.25 12H4.25Z"
						fill="#33C1D5"
					/>
				</svg>
				<div>
					<h3>Visualize suas próximas parcelas</h3>
					<h5>Valores variam de acordo com condições</h5>
				</div>
			</div>
			<div class="parcelas">
				<div
					v-for="(item, index) in vencimentos"
					class="parcela"
					:key="index"
				>
					<span>Parcela {{ item.numParcela }}</span>
					<span>Vencimento: <b>{{ item.dataVencimento }}</b></span>
					<span>{{ item.valor }}</span>
				</div>
			</div>
			<div class="btn-center">
				<b-button
					type="is-primary"
					rounded
					@click="closeModal"
				>
					Fechar
				</b-button>
			</div>
		</Modal>
	</ValidationObserver>
</template>

<script>
import Price from '@ebradi/componets/Price';
import SelectMetodoPagamento from '@ebradi/componets/SelectMetodoPagamento';
import SelectComponent from '@ebradi/componets/SelectComponent';
import CheckBox from '@ebradi/componets/CheckBox';
import FormCartao from '@ebradi/componets/FormCartao';
import Modal from '@ebradi/componets/Modal';
import Card from '@ebradi/componets/Card';
import ComprarInfo from '@ebradi/componets/ComprarInfo';
import Icon from '@components/Icon';
import SelectVencimento from '@ebradi/componets/SelectVencimento';
import {
	MetodoPagamento,
	metodoPagamentoComParcelamento,
	metodoPagamentoComVencimento,
} from '@enum/checkout';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import { currencyFormatter } from '@commons/helpers';

export default {
	name: 'FormPagamentoComponentEBRADI',
	components: {
		Icon,
		Modal,
		Price,
		Card,
		ComprarInfo,
		SelectMetodoPagamento,
		SelectComponent,
		CheckBox,
		SelectVencimento,
		ValidationProvider,
		ValidationObserver
	},
	props: {
		diasVencimento: {
			type: Array,
			required: false,
			default: () => []
		},
		gratuito: {
			type: Boolean,
			required: true
		},
		curso: {
			type: String,
			required: true
		},
		contrato: {
			type: String,
			required: true
		},
		loadingContrato: {
			type: Boolean,
			required: false,
			default: false,
		},
		cursoLivre: {
			type: Boolean,
			required: false,
			default: false,
		},
		errorCupom: {
			type: String,
			required: true
		},
		successCupom: {
			type: String,
			required: true
		},
		cupomApplied: {
			type: String,
			required: false
		},
		opcaoPagamento: {
			type: Object,
			required: true
		},
		infos: {
			type: Object,
			required: true
		},
		handleCupom: {
			type: Function,
			required: true
		},
		handleBack: {
			type: Function,
			required: true
		},
		handleParcela: {
			type: Function,
			required: true
		},
		handleSubmit: {
			type: Function,
			required: true
		},
	},
	mounted() {
		if (this.cupomApplied) {
			this.form.cupom = this.cupomApplied;
		}
	},
	methods: {
		isFormInvalid(invalid) {
      return (
        invalid ||
				!this.form.contrato_matricula ||
        (!this.cursoLivre && !this.form.declaracao_graduacao) ||
				(metodoPagamentoComVencimento.includes(this.form.metodo_pagamento?.id) && !this.form.data_vencimento) ||
				(!this.gratuito && !this.form.metodo_pagamento.id)
      );
    },
		openModal() {
			this.showModal = true;
		},
		closeModal() {
			this.showModal = false;
		},
		async cupomHandle() {
			this.form.contrato_matricula = false;
			this.loadingCupom = true;

			await this.handleCupom();

			this.loadingCupom = false;
			this.showModal = false;
		},
		submitForm() {
			if (!this.isFormInvalid(false)) {
				const payload = {
					cupom: this.form.cupom,
					dia_vencimento: this.form.data_vencimento?.replace(/\D/g, ''),
					oppa_id: this.form.metodo_pagamento.id,
					parcelas: this.form.parcelas,
					dados_cartao: this.$refs.form_dados_cartao?.getAllData(),
				};

				this.handleSubmit(payload);
			}
		},
	},
	data() {
		return {
			showModal: false,
			loadingCupom: false,
			form: {
				metodo_pagamento: {},
				cupom: null,
				parcelas: null,
				contrato_matricula: false,
				declaracao_graduacao: false,
				data_vencimento: null
			},
			metodo_pagamento: false,
			assistido: this.$route.meta.assistido,
			metodosPagamento: {
				[MetodoPagamento.CARTAO_DE_CREDITO_RECORRENTE]: FormCartao,
				[MetodoPagamento.CARTAO_DE_CREDITO_A_VISTA]: FormCartao,
				[MetodoPagamento.PIX]: null,
				[MetodoPagamento.BOLETO_RECORRENTE]: null,
				[MetodoPagamento.BOLETO_A_VISTA]: null,
			},
		}
	},
	computed: {
		metodoPagamentoComponent() {
			if (this.form.metodo_pagamento && this.form.metodo_pagamento?.id) {
				return this.metodosPagamento[this.form.metodo_pagamento.id]
			}

			return null
		},
		diasDeVencimento() {
			if (this.diasVencimento.length) {
				const sortedDias = [...this.diasVencimento].sort((a, b) => Number(a) - Number(b));
				return sortedDias.map(item => `Dia ${item}`);
			}

			return [];
		},
		metodoSelecionado() {
			return !this.gratuito && this.metodo_pagamento;
		},
		exibirParcelamento() {
			return metodoPagamentoComParcelamento.includes(this.form.metodo_pagamento?.id);
		},
		metodos() {
			return Object.values(this.opcaoPagamento);
		},
		parcelas() {
			return this.opcaoPagamento[this.form.metodo_pagamento?.id].parcelas
				.map(item => ({ id: item.numero, label: item.label }));
		},
		resumo() {
			let resumo = {};
			if (this.form.metodo_pagamento?.id && this.form.parcelas) {
				resumo = this.opcaoPagamento[this.form.metodo_pagamento?.id]
					.parcelas.find(parcela => parcela.numero === this.form.parcelas);
			}

			return resumo;
		},
		vencimentos() {
			let vencimentos = {};
			if (this.form.metodo_pagamento?.id && this.form.parcelas && this.form.data_vencimento) {
				const parcela = this.opcaoPagamento[this.form.metodo_pagamento?.id].parcelas.find(parcela => parcela.numero === this.form.parcelas)
				vencimentos = parcela.vencimentos[this.form.data_vencimento.replace(/\D/g, '')];
			}

			return Object
				.values(vencimentos).map(vencimento => ({
					numParcela: vencimento?.parcela,
					valor: currencyFormatter(vencimento?.valor),
					dataVencimento: vencimento?.vencimento,
				}));
		},
		showVencimento() {
			return this.form.metodo_pagamento?.id
				&& metodoPagamentoComVencimento.includes(this.form.metodo_pagamento?.id);
		},
		showModalVencimento() {
			return this.form.metodo_pagamento?.id
				&& this.form.data_vencimento
				&& metodoPagamentoComVencimento.includes(this.form.metodo_pagamento?.id);
		}
	},
	watch: {
		'form.metodo_pagamento'() {
			if (this.form.metodo_pagamento && this.form.metodo_pagamento?.id) {
				this.form.contrato_matricula = false;
				this.metodo_pagamento = true;
				this.form.parcelas = this.parcelas[0].id;
				this.form.data_vencimento = null;
				if (this.cupomApplied) {
					this.form.cupom = this.cupomApplied;
				}

				this.handleParcela();

				return
			}

			this.handleParcela();

			this.metodo_pagamento = false
		},
		'form.parcelas'() {
			this.form.contrato_matricula = false;
			this.handleParcela();
		},
	},
}
</script>

<style lang="scss" scoped>
.view-card-info {
	max-width: 465px;
	margin: 42px 0;

	::v-deep .ebradi-card-body {
		padding: 16px 26px;
		font-size: 16px;
		line-height: 24px;

		b {
			font-weight: 700;
		}
	}
}

.btn-center {
	padding: 42px 0px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.header-info-form {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

	h2 {
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
	}
}

.text-nao-graduacao {
	font-size: 12px;
	line-height: 24px;
	font-weight: 400;
	margin: 12px 8px;

	a {
		font-weight: 600;
	}
}

.parcelas {
	align-items: center;
	border-radius: 16px;
	display: flex;
	flex-wrap: wrap;
	gap: 12px;
	justify-content: center;
	margin: auto;
	max-height: 420px;
	overflow: auto;
	padding: 22px;
	margin-right: 30px!important;

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background: #888;
		border-radius: 5px;
	}

	.parcela {
		width: 100%;
		max-width: 25%;
		padding: 8px;
		border-radius: 12px;
		border: solid 1px var(--primary);

		span {
			display: block;
			font-size: 12px;
			line-height: 24px;
			width: 100%;

			&:nth-child(3) {
				font-weight: 700;
				color: var(--primary);
			}
		}
	}
}

.header-parcelamento {
	margin-top: 62px;

	.select-parcelas {
		margin-top: 32px;

		::v-deep .select {
			max-width: 270px !important;
			width: 100% !important;

			select {
				max-width: 270px !important;
				width: 100% !important;
				padding-left: 16px;

				font-weight: 600;
				font-size: 14px;
			}

			&::after {
				display: none !important;
			}

		}
	}

	::v-deep {
		.select.is-empty {
			&::after {
				display: none;
			}
		}
	}

	h2 {
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
	}

	&>.colunas {
		margin-top: 42px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;


		&>div:last-child {
			width: 300px;

			::v-deep .field {
				margin-bottom: 26px;
			}
		}
	}
}

.float-right {
	float: right;
}

.link-cupom {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 8px;
	transition: 0.4s;

	&:hover {
		opacity: 0.6;
	}
}

.header-modal-parcela {
	text-align: center;
	color: var(--primary);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 22px;
	padding: 22px 0;

	& > .time {
		position: absolute;
		top: 20px;
		right: 40px;
		font-weight: 700;
		font-size: 16;
		cursor: pointer;
		transition: 0.4s;

		&:hover {
			color: var(--primary);
		}
	}

	svg {
		margin-right: 18px;
		width: 40px;
		height: 40px;
	}

	div {
		h3 {
			font-size: 24px;
			line-height: 28px;
			font-weight: 700;
		}

		h5 {
			font-size: 16px;
			line-height: 24px;
			font-weight: 400;
			color: #fff;
			margin-top: 8px;
		}
	}
}

.btns {
	text-align: center;
	margin-top: 82px;
	margin-bottom: 22px;

	.btn-margin-right {
		margin-right: 32px;
	}
}

p.help.no-icon.is-danger {
	color: #C93F58!important;
}

p.help.no-icon.is-info {
	color: #53A7BD!important;
}

@media screen and (max-width: 900px) {
	.header-parcelamento {
		&>.colunas {
			flex-wrap: wrap;
			margin-top: 22px;

			&>div {
				width: 100% !important;

				&:last-child {
					margin-top: 22px;
				}

			}
		}
	}

	.header-modal-parcela {
		svg {
			width: 24px;
			height: 36px;
		}

		div {
			text-align: left;

			h3 {
				color: #fff;
				font-size: 14px;
				line-height: 24px;
			}

			h5 {
				font-size: 12px;
				margin-top: 2px;
			}
		}
	}

	.parcelas {
		.parcela {
			display: flex;
			width: 100%;
			margin: 0 22px;
			max-width: 100%;
			justify-content: center;
			align-items: center;
			flex-wrap: nowrap;

			span {
				display: inline-block;
				font-size: 10px!important;
				order: 1;
				font-weight: 500;

				&:nth-child(2), &:nth-child(3) {
					&::before {
						content: '|';
						margin: 0 8px;
						color: #fff;
					}
				}

				&:nth-child(2) {
					order: 2;
				}
			}
		}
	}
}
</style>
